import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faList, faCommentDots, faComment, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalWithInput } from '../manageGroup/modal';
import './task.css';

// taskList/index.js
// display tasks
export function ShowTasksTable(props) {
    const list = props.list;
    return <table className="table">
        <thead>
            <tr>
                <th className={list.display ? '' : "inactive-list-header"}>Type</th>
                <th className={list.display ? '' : "inactive-list-header"}>Name</th>
            </tr>
        </thead>
        <tbody>
            {list?.tasks?.map((task, index) => <tr key={`${task.name}-${index}`}>
                <td>{task.type === "checkbox" ? <input type="checkbox" /> : "Notes"}</td>
                <td>{task.name}</td>
            </tr>)}
        </tbody>
    </table>
}

// taskList/index.js
// create or edit tasks
export function CreateOrEditTasksTable(props) {
    const newTasks = props.newTasks;
    return <table className="table">
        <thead>
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Delete</th>
            </tr>
        </thead>
        <tbody>
            {newTasks.map((task, index) => <tr key={`new-task-${index}`}>
                <td><div className="select">
                    <select value={task.type || "checkbox"} name="task-type" id={index} onChange={props.changeHandler}>
                        <option key={`task-type-0-${index}`} className="capitalize-me" value="checkbox">Checkbox</option>
                        <option key={`task-type-1-${index}`} className="capitalize-me" value="text">Text</option>
                    </select>
                </div></td>
                <td><input className="input" type="text" name="task-name" id={index} onChange={props.changeHandler} value={task.name} /></td>
                <td><FontAwesomeIcon className="clickable" icon={faMinusCircle} onClick={() => props.removeTask(index, props.adding)} /></td>
            </tr>)}
        </tbody>
    </table>
}

function NoteInput(props) {
    const task = props.task;
    return <div className="modal" style={{ display: "block" }}>
        <div className="modal-background"></div>
        <div className="modal-card shift-modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Add Task Note</p>
                <button className="delete" aria-label="close" onClick={() => props.toggleNotes(props.j)}></button>
            </header>
            <section className="modal-card-body">
                <form onSubmit={(e) => { props.handleNote(e, props.list, props.j); props.toggleNotes(props.j); }}>
                    <div>
                        <textarea className="textarea my-2" name="complete" defaultValue={task.complete} placeholder="Leave a note for completing this task..." />
                        <input className="button" type="submit" value="Submit Note" />
                    </div>
                </form>
            </section>
        </div>
    </div>
}

// home/currentShift.js
// employee view and complete tasks
export function CurrentShiftTasksTable(props) {
    const list = props.list;
    return <table className="table">
        <thead >
            <tr>
                <th className="current-shift-list-header"><p className="is-size-5">Group Tasks</p></th>
                <th className="current-shift-list-header"><p className="is-size-5">Completion</p></th>
                <th className="current-shift-list-header"><p className="is-size-5">Updated By</p></th>
                <th className="current-shift-list-header"><p className="is-size-5">Updated Time</p></th>
            </tr>
        </thead>
        <tbody>
            {list?.tasks?.map((task, j) => <tr key={`${task.name}-${j}`}>
                <td>
                    {task.type === "checkbox" ? <input type="checkbox" defaultChecked={task.complete} onChange={() => props.handleCheck(list, j)} /> :
                        <>
                            <FontAwesomeIcon className="clickable" icon={task.complete ? faCommentDots : faComment} onClick={() => props.toggleNotes(list.listId, j)} />
                            {props.showNotes && props.showNotes[list.listId] === j ? < NoteInput handleNote={props.handleNote} task={task} list={list} j={j} toggleNotes={props.toggleNotes} /> : ''}
                        </>}
                </td>
                <td><p className="is-size-5">{task.name}</p></td>
                <td><p className="is-size-5">{task.complete? task.updatedBy : '-'}</p></td>
                <td><p className="is-size-5">{task.complete && task.updatedAt ? new Date(task.updatedAt * 1000).toLocaleTimeString() : '-'}</p></td>
            </tr>)}
        </tbody>
    </table>
}

// taskList/index.js
// show tasks history
export function CompletionTasksTable(props) {
    const list = props.list;
    return <>
        <ModalWithInput input={props.logs} closeModal={props.closeLogs} />
        <table className="table">
            <thead>
                <tr>
                    <th>Completion</th>
                    <th>Name</th>
                    <th>Updated By</th>
                    <th>Updated Time</th>
                    <th>Logs</th>
                </tr>
            </thead>
            <tbody>
                {list.tasks.map((task, j) =>
                    <tr key={`${list.listId}-${j}`}>
                        <td>{task.type === "checkbox" ? (task.complete ?
                            <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />) :
                            (task.complete ?
                                <FontAwesomeIcon className="clickable" icon={faCommentDots} onClick={() => props.showLogs([{ note: task.complete }])} /> :
                                <FontAwesomeIcon icon={faComment} />)}</td>
                        <td>{task.name}</td>
                        <td>{task.complete? task.updatedBy : '-'}</td>
                        <td>{task.complete&&task.updatedAt ? new Date(task.updatedAt * 1000).toLocaleString() : '-'}</td>
                        <td>{task.logs ?
                            <FontAwesomeIcon className="clickable" icon={faList} onClick={() => props.showLogs(task.logs)} /> : '-'}</td>
                    </tr>
                )}
            </tbody>
        </table>
    </>
}