import React, { Component } from 'react';
import axios from 'axios';
import format from "date-fns/format";

class TakeABreakModal extends Component {
  constructor(props) {
    super(props);
    this.state = { breakStart: null, breakEnd: null, breakMinutes: 5 }
  }

  logAction = (reason) => {
    axios.get("/users/log/" + reason)
      .then((response) => {
        console.log(response.data);
      }).catch((error) => {
        console.log(error)
      });
  }

  timeToEpoch = (time) => {
    let epochTime = new Date(parseInt(this.props.shift.start_time * 1000));
    const breakTime = time.split(":");
    epochTime.setHours(parseInt(breakTime[0]))
    epochTime.setMinutes(parseInt(breakTime[1]))
    epochTime = epochTime.getTime()
    return epochTime
  }

  formModal = () => {
    if (this.props.modal) {
      let startTime = new Date(this.props.shift.start_time * 1000);
      let endTime = new Date(this.props.shift.end_time * 1000);
      let startBreak = this.state.breakStart ? format(this.timeToEpoch(this.state.breakStart), "hh:mm aaa") : '';
      let endBreak = this.state.breakEnd ? format(this.timeToEpoch(this.state.breakEnd), "hh:mm aaa") : '';
      let secondShift = this.state.breakEnd ? this.props.shift.end_time * 1000 > this.timeToEpoch(this.state.breakEnd):true;
      startTime = format(startTime, "hh:mm aaa");
      endTime = format(endTime, "hh:mm aaa");
      let options = [];
      for (let i = 5; i <= 60; i += 5) {
        options.push(<option value={i} key={i}>{i} mins</option>)
      }
      return (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Take A Break</p>
              <button onClick={this.props.onClose} className="delete" aria-label="close"></button>
            </header>
            <section className="modal-card-body">
              <label className="label">Shift 1: {startTime} - {startBreak}</label>
              <form>
                <div className="field">
                  <label className="label">Start from: </label>
                  <div className="control">
                    <input className="input" name="breakStart" type="time" value={this.state.breakStart ? this.state.breakStart : ''}
                      onChange={this.changeHandler} />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Break for: </label>
                  <div className="control">
                    <div className="select" onChange={this.changeHandler}>
                      <select name="breakMinutes" defaultValue={this.state.breakMinutes}>
                        {options}
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {secondShift? <label className="label">Shift 2: {endBreak} - {endTime}</label>:<label className='label'>Till shift ends at {endTime}</label>}
            </section>
            <footer className="modal-card-foot">
              <div className="control">
                <input className="button is-dark" type='submit' onClick={this.submitForm} />
              </div>
            </footer>
          </div>
        </div>
      );
    }
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log("value", value)
    this.setState({
      [name]: value
    }, () => {
      if (this.state.breakMinutes && this.state.breakStart) {
        let breakStart = this.state.breakStart.split(":");
        let hour = parseInt(breakStart[0]);
        let minute = parseInt(breakStart[1]);
        let increment = parseInt(this.state.breakMinutes) + minute;
        let breakEnd = `${hour}:${increment}`
        this.setState({ breakEnd: breakEnd })
      }
    });
  }

  verifyTime = () => {
    //verify that user provided a value
    if (!this.state.breakStart) { return alert("Please Select a break start time") }
    let startEpoch = this.timeToEpoch(this.state.breakStart);

    let startTime = new Date(parseInt(this.props.shift.start_time * 1000))
    let endTime = new Date(parseInt(this.props.shift.end_time * 1000))

    //verify the selected time is after the start and before the end
    if ((startEpoch <= startTime) || (startEpoch >= endTime)) { return alert("Please take a break during your shift") }
    return true;
  }


  submitForm = (event) => {
    event.preventDefault();
    if (this.verifyTime() && window.confirm("Make sure you clock in to your shift after the break!")) {
      let startEpoch = this.timeToEpoch(this.state.breakStart);
      let endEpoch = this.timeToEpoch(this.state.breakEnd);
      let endTime = this.props.shift.end_time * 1000;
      // In case the break ends after shift ends
      if (endEpoch >= endTime) {
        endEpoch = endTime;
      }
      const body = { shiftId: this.props.shift.shiftId, startEpoch: startEpoch / 1000, endEpoch: endEpoch / 1000 }
      console.log(body);
      axios.post("shifts/break/", body).then((response) => {
        this.props.refreshShifts();
        this.props.onClose();
        this.g(`took a break for ${this.state.breakMinutes} mins`);
      }).catch(function (err) {
        console.log(err)
      });
    }
  }

  render() {
    return (
      <div>
        {this.formModal()}
      </div>
    );
  }
}

export default TakeABreakModal