import React, { Component } from 'react';
import axios from 'axios';
import replacement from '../profile/duke.png'
import { format, isToday, isTomorrow } from "date-fns";
import './digitalSign.css'
import placeholder from './duke.png';

// let dimensionsMap = new Map([
//   ['TEC', [641, 300]],
//   ['Ruby', [641, 300]],
//   ['MPS', [641, 300]]
// ])

class DigitalSign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShifts: null,
      nextShift: null,
      coverageStart: null,
      coverageEnd: null
    }
  }

  showImage = (student, shift) => {
    if (student?.netid) {
      let netid = student.netid;
      axios.get(
        `publicCalendars/profilePic/user/${netid}`
      ).then((response) => {
        let image = replacement;
        if (response.data !== '' && response.data) {
          image = response.config.baseURL + '/' + response.config.url;
        }
        if (shift === "next") {
          this.setState({ next_image: image });
        } else {
          let newCurrentShifts = this.state.currentShifts;
          let index = newCurrentShifts.indexOf(student);
          newCurrentShifts[index].image = image;
          this.setState({ currentShifts: newCurrentShifts });
        }
      }).catch((error) => {
        console.log(error)
      });
    }
  }

  componentDidMount = () => {
    this.getData();
  }

  showXML = () => {
    axios.get("publicCalendars/digitalSign").then(response => {
    })
      .catch((error) => {
        console.log("error: ", error)
      });
  }

  getCurrentStaffers = (group, location) => {
    axios.get("publicCalendars/digitalSign/current/" + group + "/" + location).then(response => {
      let data = response.data;
      if (data.length !== 0) {
        data.forEach(d => this.showImage(d));
        this.setState({ currentShifts: data })
      }
    }).catch((error) => {
      console.log("error: ", error)
    });
  }

  getCoverages = (allShifts) => {
    if (allShifts.length === 0) {return []};
    allShifts.sort((a, b) => {return a.start - b.start})
    let coverages = [];
    let start = allShifts[0].start;
    let end = allShifts[0].end;
    allShifts.forEach((s) => {
      if (s.start <= end) {
        end = end < s.end ? s.end : end;
      } else {
        coverages.push({start: start, end: end});
        start = s.start;
        end = s.end;
      }
    })
    coverages.push({start: start, end: end});
    return coverages;
  }

  getCoverageAndNextShift = (group, location) => {
    let now = new Date();
    axios.get(`publicCalendars/find_time_group_location_fc/${now.setHours(0, 0, 0, 0) / 1000}/${now.setHours(24, 0, 0, 0) / 1000}/${group}/${location}`).then(response => {
      let coverages = this.getCoverages(response.data);
      let coverageStart, coverageEnd, nextShift;
      let now = Date.now();
      for (let i=0; i<coverages.length; i++){
        let c = coverages[i];
        if (c.start <= now && now <= c.end) {
          coverageStart = c.start;
          coverageEnd = c.end;
          if (i === coverages.length - 1) {
            this.getNextShift(coverageEnd, group, location);
          } else {
            nextShift = coverages[i + 1];
          }
          break;
        } else if (now < c.start) {
          nextShift = c;
          break;
        }
      }
      this.setState({
        coverageStart: coverageStart,
        coverageEnd: coverageEnd,
        nextShift: nextShift
      })
    });
  }

  getNextShift = (coverageEnd, group, location) => {
    axios.get(`publicCalendars/digitalSign/day/${coverageEnd}/${group}/${location}`).then(response => {
      let coverages = this.getCoverages(response.data);
      this.setState({
        nextShift: coverages[0]
      })
    }).catch((error) => {
      console.log("error: ", error)
    });
  }

  getData = () => {
    let group;
    let location;
    if (this.props.location === "MPS") {
      group = "MPS";
      location = "MPS";
    } else if (this.props.location === "Ruby") {
      group = "CoLab Studios";
      location = "Ruby";
    } else if (this.props.location === "TEC"){
      // local
      //group = "colab student devs";
      // dev and production
      group = "CoLab Studios";
      location = "TEC";
    } else if (this.props.location === "Lilly") {
      group = "CoLab Studios";
      location = "Lilly";
    }
    this.getCurrentStaffers(group, location);
    this.getCoverageAndNextShift(group, location);
  }

  // format date. show minutes if time isn't on the hour. (example: 9AM, 6:30PM)
  formatHour = (date) => {
    if (format(date, "m") === "0") {
      return format(date, "haaa");
    } else {
      return format(date, "h:mmaaa");
    }
  }

  formatDay = (time) => {
    if (isToday(time)) {
        return `Today`
    } else if (isTomorrow(time)) {
        return 'Tomorrow'
    } else {
        return format(time, "EEEE MMM. do");
    }
  }

  displayHead = () => {
    let title = `Self-Service ${this.props.location}`;
    let style = {};
    if (this.state.currentShifts) {
      style = {'--top-color': '#e99b00', '--bottom-color': '#e5ba6f', '--font-color': '#fefcf8'};
      title = `${this.props.location} Staff`
    }
    return (
      <div id='digital-sign-head' style={style}>
        <div id='head-content'>
          <h3>INNOVATION CO-LAB STUDIOS</h3>
          <h1>{title}</h1>
        </div>
      </div>
    )
  }

  displayBody = () => {
    let backgroundColor = {};
    if (this.state.currentShifts) {
      backgroundColor = {'--top-color': '#fefcf8', '--bottom-color': '#fefcf8'}
    }
    return (
      <div id='digital-sign-body' style={backgroundColor}>
        <div id='body-content'>
          {this.displayShiftOrText()}
          {this.displayNextShift()}
        </div>
      </div>
    )
  }

  displayShiftOrText = () => {
    let fontColor = {};
    let text = `The ${this.props.location} is currently in self-service mode. You are welcome to use the openly accessible areas, and staff will be back soon to assist with any project or equipment support needs.`;
    if (this.props.location === "TEC") {
      text = `The ${this.props.location} is currently in self-service mode. You are welcome to use the openly accessible areas, and staff will be back soon to open the garage and assist with any project or equipment support needs.`;
    }
    let bios = [];
    if (this.state.currentShifts) {
      fontColor = {'--main-color': '#77a8f3', '--secondary-color': '#4f8deb'};
      text = `Find us in the ${this.props.location} for help with equipment, design, software, or anything else we support.`;
      let staffersToShow = this.state.currentShifts.length < 2 ? this.state.currentShifts.length : 2
      for (let i = 0; i < staffersToShow; i++){
        let s = this.state.currentShifts[i];
        let nameArray = s.user_name.split(" ");
        bios.push(<div className='staffer-bio'>
          <img className='staffer-photo' src={s.clocked_in ? s.image : placeholder} alt="person on shift" />
          <p className='secondary'>{nameArray.length === 2? nameArray[0] + " " + nameArray[1][0] + ".":s.user_name}</p>
        </div>)
      }
    }
    return (
      <div id='current-shift' style={fontColor}>
        <p className='main' id="body-blob">{text}</p>
        <div id='shifts'>
          {bios}
          {this.displayCoverage()}
        </div>
      </div>
    )
  }

  displayCoverage = () => {
    let coverage;
    if (this.state.coverageEnd && this.state.coverageStart) {
      coverage = `${this.formatHour(this.state.coverageStart)} - ${this.formatHour(this.state.coverageEnd)}`
      return (
        <div className='staffed-hours'>
          <h2 className="secondary">Current Staffed Hours</h2>
          <h1 className='main'>{coverage}</h1>
        </div>
      )
    }
  }

  displayNextShift = () => {
    let fontColor = {};
    if (this.state.currentShifts) {
      fontColor = {'--font-color': '#c5dcff'};
    }
    if (this.state.nextShift){
      return (
        <div id='next-shift' style={fontColor}>
          <p>Next Shift</p>
          {<h2>{this.formatDay(this.state.nextShift.start)}, {this.formatHour(this.state.nextShift.start)} - {this.formatHour(this.state.nextShift.end)}</h2>}
        </div>
      )
    }
  }

  render() {
    return (<div>
      {this.showXML() /*keeping this here for now so we don't break existing funcitonality */}
      <div id="sign-container">
        {this.displayHead()}
        {this.displayBody()}
      </div>
    </div>);
  }
}

export default DigitalSign;