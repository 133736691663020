import React, { Component } from 'react';
import axios from 'axios';
import format from 'date-fns/format'
import ShiftsDetailTable from './shiftsDetailTable.js';
import WeeklyHourTable from './weeklyHourTable.js';
import Flash from '../../common/flash.js';

// Display employee's view including the WeeklyHourTable and ShiftsDetailTable
class EmployeeView extends Component {

   constructor(props) {
      super()
      this.state = {
         adhoc: false, modal: false, clocked_in: false, clocked_out: false,
         group: false, note: '', showDetails: false
      }
   }

   componentDidMount = () => {
      this.getAdhocStatus();
   }

   componentDidUpdate(prevProps) {
      let changedProps = prevProps.data !== this.props.data
      if (changedProps) {
         this.getAdhocStatus();
      }
   }

   getAdhocStatus = () => {
      let netid = localStorage.getItem('netid');
      axios.get("workGroups/findAdHoc").then((response) => {
         //   console.log("RESPONSE", response.data)
         if (response.data.adhoc && response.data.adhoc.includes(netid)) {

            this.setState({ adhoc: true });
         }
      }).catch((error) => {
         console.log(error)
      });
   }

   openModal = () => {
      this.setState({ modal: true })
   }

   closeModal = () => {
      this.setState({ modal: false })
   }

   drawHeader = () => {
      let adhocAdd;
      let shiftDetails;
      if (this.state.adhoc) {
         adhocAdd = <CustomButton className="button is-light" type="button" onClick={this.openModal} text="Add Hour" />
      }
      if (localStorage.getItem("role") === "employee") {
         shiftDetails = <CustomButton className="button is-light" type="button" onClick={this.toggleDetails} text={this.state.showDetails ? "Hide Shifts Details" : "Show Shifts Details"} />
      }
      if (this.props.data) {
         let showTotalHours = <table className="table is-fullwidth">
            <thead>
               <tr>
                  <th><h1 className='title is-6 table-head'>Scheduled Hours</h1></th>
                  <th><h1 className='title is-6 table-head'>Total Clocked Hours</h1></th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td><h1 className="title">{Math.round(this.props.data.total_shift_hours / 360) / 10}</h1></td>
                  <td><h1 className="title">{Math.round(this.props.data.total_clocked_hours / 360) / 10}</h1></td>
               </tr>
            </tbody>
         </table>
         return <div>
            {showTotalHours}
            <h1 className="title py-4">Work Log</h1>
            <div className="field is-horizontal">
               {adhocAdd}
               <span className='spacer'></span>
               {shiftDetails}
            </div>
            <br />
         </div>
      }
   }

   toggleDetails = () => {
      this.setState({ showDetails: !this.state.showDetails })
   }

   drawShifts = () => {
      if (this.props.data) {
         let employeeShifts = <ShiftsDetailTable data={this.props.data} getShifts={this.props.getShifts} type="all" employeeView={true} />
         return <>
            <WeeklyHourTable data={this.props.data} getShifts={this.props.getShifts}
               startDate={this.props.startDate} endDate={this.props.endDate} />
            <br />
            {this.state.showDetails ? employeeShifts : ''}
         </>
      } else {
         return <h2 className='title is-5'>You have no shifts from {format(this.props.startDate, 'MM-dd')} to {format(this.props.endDate, 'MM-dd')}.</h2>
      }
   }

   // change handler for input type time
   changeTimeHandler = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({ [name]: Date.parse(value) })
   }

   changeNoteHandler = (event) => {
      const value = event.target.value;

      this.setState({ note: value })
   }

   // generic change handler
   changeHandler = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
         [name]: value
      });
   }

   // log user activity
   logAction = (reason) => {
      axios.get("/users/log/" + reason)
         .then((response) => {
            console.log(response.data);
         }).catch((error) => {
            console.log(error)
         });
   }

   submitForm = async (event) => {
      event.preventDefault();

      if (!this.state.clocked_in || !this.state.clocked_out || !this.state.group) {
         this.setState({ error: ["Please complete the form!"] })
      } else if (this.state.clocked_in >= this.state.clocked_out) {
         this.setState({ error: ["Please have clock in before clock out!"] })
      } else {
         let employee;
         if (this.props.data.shifts.length > 0) {
            employee = this.props.data.shifts[0].employee;
         } else {
            let response = await axios.get("users/find/" + localStorage.getItem('netid'));
            if (response.status !== 200) {
               console.log("Did not find this group:", localStorage.getItem('netid'), ", got error:", response.status);
            } else {
               employee = response.data;
            }
         }
         let shift = {
            clocked_in: this.state.clocked_in / 1000,
            clocked_out: this.state.clocked_out / 1000,
            start_time: this.state.clocked_in / 1000,
            end_time: this.state.clocked_out / 1000,
            employee: employee,
            group: this.state.group,
            note: this.state.note
         }

         axios.post("shifts/createAdhoc", shift).then((response) => {
            this.closeModal();
            this.props.getShifts();
            this.logAction("added adhoc shift");
            window.location.href = './hours_and_reports'
         }).catch(function (err) {
            console.log(err)
         });

      }
   }

   mapGroup = () => {
      let options = []
      let groups = localStorage.getItem("group").split(",");
      if (groups) {
         groups.map((group) =>
            options.push(<option key={group} value={group}> {group}</option>)
         )
      }
      return (options)
   }

   showModal = () => {
      if (this.state.modal) {
         return (
            <div className="modal is-active">
               <div className="modal-background"></div>
               <div className="modal-card">
                  <header className="modal-card-head">
                     <p className="modal-card-title">Add a Shift</p>
                     <button onClick={this.closeModal.bind(this)} className="delete" aria-label="close"></button>
                  </header>

                  <section className="modal-card-body">
                     {this.flash()}
                     <form>
                        <div className="field">
                           <label className="label">Clock In</label>
                           <div className="control">
                              <input
                                 className="input"
                                 type="datetime-local"
                                 name="clocked_in"
                                 onChange={this.changeTimeHandler}
                              />
                           </div>
                        </div>

                        <div className="field">
                           <label className="label">Clock Out</label>
                           <div className="control">
                              <input
                                 className="input"
                                 type="datetime-local"
                                 name="clocked_out"
                                 onChange={this.changeTimeHandler}
                              />
                           </div>
                        </div>

                        <div className="field">
                           <label className="label">Group</label>
                           <div className="control">
                              <div className="select">
                                 <select name="group" defaultValue="select_a_group" onChange={this.changeHandler}>
                                    <option disabled="disabled" value="select_a_group" hidden="hidden">-- Select a Group --</option>
                                    {this.mapGroup()}
                                 </select>
                              </div>
                           </div>
                        </div>

                        <div className="field">
                           <label className="label">Note</label>
                           <div className="control">
                              <input
                                 className="input"
                                 name="note"
                                 onChange={this.changeNoteHandler}
                              />
                           </div>
                        </div>
                     </form>
                  </section>
                  <footer className="modal-card-foot">
                     <div className="control">
                        <input className="button is-link" type='submit' onClick={this.submitForm} />
                     </div>
                  </footer>
               </div>
            </div>
         )
      }
   }

   setFlash = (error, success) => {
      this.setState({ error: error, success: success });
   }

   closeFlash = () => {
      this.setState({ error: false, success: false })
   }

   //show error message
   flash = () => {
      if (this.state.error) {
         return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
      } else if (this.state.success) {
         return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
      }
   }

   render() {
      return (
         <div>
            {this.drawHeader()}
            {this.drawShifts()}
            {this.showModal()}
         </div>
      )
   }
}

export default EmployeeView;

// component helpers
function CustomButton(props) {

   return (
      <div className="control">
         <button className={props.className} type={props.type} onClick={props.onClick}>{props.text}</button>
      </div>
   )
}