import React, { Component } from 'react';
import axios from 'axios';
import format from 'date-fns/format'
import Clock from '../../assets/clock.png';
import getUnixTime from 'date-fns/getUnixTime'
import { teamsNotify } from '../helpers/teams';
import { CurrentShiftTasksTable } from '../taskList/taskTables';

class CurrentShift extends Component {
  constructor(props) {
    super(props);
    this.state = { clockedIn: false, clockedOut: false, shifts: [], currentTasks: [], showNotes: false };
  }

  componentDidMount() {
    let group = localStorage.getItem('group').split(",")[0];
    this.findGroupLists(group);
    this.setState({ ipGroup: group })
  }


  findGroupLists = async (value) => {
    let response = await axios.get("tasklists/displayList/" + value);
    if (response.status !== 200) {
      console.log("could not find group");
    } else {
      let data = response.data;
      // console.log("data", data)
      this.setState({ currentTasks: data })
    }
  }

  logAction = (reason) => {
    axios.get("/users/log/" + reason)
      .then((response) => {
        // console.log(response.data);
      }).catch((error) => {
        console.log(error)
      });
  }

  drawShifts = () => {
    let message = "";
    let shifts = [];
    if (this.props.shifts) {
      if (localStorage.getItem('role')) {
        shifts.push(this.mapShifts());
        if (this.props.shifts[0].hasOwnProperty('clocked_in')) {
          shifts.push(
            <button className="clock-in" key="break button" onClick={() => this.props.handleBreak(this.props.shifts[0])}>
              <img className="clock" src={Clock} alt="Clock" />
              <span className="clock-text">Take a Break</span>
            </button>
          );
          message = "Clock Out";
        } else {
          message = "Clock In";
        }
        shifts.push(
          <button className="clock-in" key="button" onClick={() => this.handleClick(this.props.shifts[0])}>
            <img className="clock" src={Clock} alt="Clock" />
            <span className="clock-text">{message}</span>
          </button>
        );
      } else {
        shifts.push(
          <div key="shifts" className="landing-page-shifts">
            {this.mapShifts()}
          </div>
        );
      }
      return (
        <div>
          {shifts}
        </div>
      );
    }
  }

  mapShifts = () => {
    let shifts = this.props.shifts;
    let timeFormat = "hh:mm";
    let pm = "a";
    return shifts.map((shift, index) => {
      let person = "Open Shift";
      if (shift.employee && (localStorage.getItem('role') === 'supervisor' || localStorage.getItem('role') === 'admin')) {
        let displayName = shift.employee.display_name;
        let nameArray = displayName.split(" ");
        person = nameArray.length === 2 ? nameArray[0] + " " + nameArray[1].charAt(0) + "." : displayName;
      }
      let location = <p className="shift-location">{person} @ {shift.location} </p>;
      let item = (
        <div>
          <p className="shift-time">{format(shift.start_time * 1000, timeFormat)}<span className="pm">{format(shift.start_time * 1000, pm)}</span> &#8594; {format(shift.end_time * 1000, timeFormat)}<span className="pm">{format(shift.end_time * 1000, pm)}</span></p>
          {location}
          <p className="shift-role"> {shift.group} </p>
          <p className="shift-role" hidden={shift.note ? false : true}> <span className="bold">Note: </span> {shift.note} </p>
        </div>
      );
      if (localStorage.getItem('role') === 'supervisor' || localStorage.getItem('role') === 'admin') {
        return (
          <div className="WHICH" key={index}>
            <div className="next-shift-box">
              {item}
            </div>
          </div>
        );
      } else {
        return (
          <div key={index}>
            <div className="transparent-box landing-employee">
              {item}
            </div>
          </div>
        );
      }
    }
    );
  }

  handleClick = async (shift) => {
    let time = getUnixTime(Date.now());
    // console.log(shift);
    let group = shift.group.trim();
    if (time + 600 < shift.start_time && !shift.hasOwnProperty('clocked_in')) {
      // 10 minutes before or earlier
      this.props.setFlash("It is too early to clock in to your shift! Please wait until 10 minutes before to clock in.", false);
    } else {
      let result = await axios.get("workGroups/findIPRange/" + group);
      if (result && !result.data) {
        this.props.setFlash("You cannot clock in/out outside the ip range of your work place!", false);
        return;
      }
      if (shift.hasOwnProperty('clocked_in') && window.confirm("Are you sure you want to clock out?")) {
        let response = await axios.get("workGroups/findIPRange/" + group);
        if (response.status !== 200) {
          this.props.setFlash("could not find group", false);
        } else {
          let clock_out_ip_in_range = response.data;
          axios.put("shifts/clock_out/" + this.props.shifts[0]._id, { clocked_out: time, status: "completed", clock_out_ip_in_range: clock_out_ip_in_range }).then((response) => {
            this.logAction("clocked out");
            teamsNotify(shift, group, `${shift.employee.display_name} has clocked out`);
            this.props.getShifts();
          }).catch(function (err) {
            console.log(err)
          });
        }
      } else if (!shift.hasOwnProperty('clocked_in') && window.confirm("Are you sure you want to clock in?")) {
        let response = await axios.get("workGroups/findIPRange/" + group);
        if (response.status !== 200) {
          this.props.setFlash("could not find group", false);
        } else {
          let clock_in_ip_in_range = response.data;
          axios.put("shifts/clock_in/" + this.props.shifts[0]._id, { clocked_in: time, clock_in_ip_in_range: clock_in_ip_in_range }).then((response) => {
            this.logAction("clocked in");
            teamsNotify(shift, group, `${shift.employee.display_name} has clocked in`);
            this.props.getShifts();
          }).catch(function (err) {
            console.log(err)
          });
        }
      }
    }
  }

  determineMessage = () => {
    let landing = "You have no shifts left today."
    if (this.props.shiftsToday > 0) {
      if (this.props.shiftsToday === 1) {
        landing = "You have one shift today."
      } else {
        landing = "You have " + this.props.shiftsToday + " shifts today."
      }
    }
    return landing;
  }

  renderShift = () => {
    let zeroShifts = "Enjoy the time off!"
    if (this.props.shiftsToday === 0 || this.state.clockedOut) {
      return (
        <div key="no-shift">
          <div className="next-shift-box" key="no-shift">
            <p className="no-shifts">{zeroShifts}</p>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className='clocking-in' key="shift">
          {this.drawShifts()}
        </div>
      );
    }
  }

  updateTaskProgress = async (list, taskId, complete) => {
    let newList = list;
    newList.tasks[taskId].complete = complete;
    newList.tasks[taskId].updatedBy = localStorage.getItem('netid');
    const time = new Date();
    newList.tasks[taskId].updatedAt = time / 1000;
    if (!newList.tasks[taskId].logs || typeof (newList.tasks[taskId].logs) !== "object") {
      newList.tasks[taskId].logs = []
    }
    newList.tasks[taskId].logs.push({ complete: complete, updatedBy: localStorage.getItem('netid'), updatedAt: time / 1000 })
    // console.log("new tasks", newList)
    let response = await axios.put(`tasklists/update/${list.listId}`, newList.tasks);
    if (response.status !== 200) {
      console.log("could not find group");
    } else {
      this.findGroupLists(this.state.ipGroup);
    }
  }

  handleCheck = (list, taskId) => {
    this.updateTaskProgress(list, taskId, !list?.tasks[taskId]?.complete);
  }

  handleNote = async (e, list, taskId) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const complete = formData.get('complete');
    this.updateTaskProgress(list, taskId, complete);
  }

  toggleNotes = (listId, index) => {
    if (this.state.showNotes === index) {
      this.setState({ showNotes: false })
    } else {
      this.setState({ showNotes: { [listId]: index } })
    }
  }

  render() {
    let greeting = <div className="greeting" key="greeting">
      <h1>Hello, <span>{this.props.name}</span>.</h1>
      <h2>{this.determineMessage()}</h2>
      <br />
    </div>
    let currentShift = this.renderShift();
    let currentTasks = this.state.currentTasks.map((list, index) =>
      <div key={`table-${index}`}>
        <h3 className="title is-4" style={{ color: 'white' }}>{list.group} {list.listName || "Group List"}</h3>
        <CurrentShiftTasksTable list={list} showNotes={this.state.showNotes} toggleNotes={this.toggleNotes} handleNote={this.handleNote} index={index} handleCheck={this.handleCheck} />
        <br />
      </div>)
    const noTasks = currentTasks.length <= 0;
    return (
      <div className="home-panel">
        <div className="columns">
          <div className="column is-5">
            {greeting}
            {noTasks ? '' : currentShift}
          </div>
          <div className="column">
            {noTasks ? currentShift : ''}
            {currentTasks}
          </div>
        </div>
        <div className="indicator is-bold">
          <span className="arrow">&#8595; </span>
          <a href="#upcoming-shifts">Scroll for Upcoming Shifts</a>
        </div>
      </div>
    );
  }
}

export default CurrentShift;
