import React, { Component } from "react";
import axios from 'axios';
import format from "date-fns/format";

export default class UpcomingShifts extends Component {
    constructor(props) {
      super(props);
      this.state = { mergeShift: false,}
    }

    offerUpShift = (shift) => {
        let group = this.props.groups.find(group => group.name === shift.group);
        console.log("group is: ", group)
        let newStatus;
        let message;
        //Offer-Up, Wait for Supervisor Release
        if (group.requireCover) {
            message = 'Are you sure you want to offer up this shift? If no one picks up your shift, you are still required to cover it.';
            newStatus = { status: "open", employee: shift.employee }
        } else { //Offer-Up, Automatically release
            message = 'Are you sure you want to offer up this shift?';
            newStatus = { status: "open", employee: null }
        }
        if (window.confirm(message)) {
            axios.put("shifts/offer_up/" + shift._id, newStatus)
                .then((response) => {
                    this.props.updateAndNotify(shift, group);
                }).catch((error) => {
                    console.log(error);
                });
        }
    }

    mergeOption = (shift) => {
      if (shift.start_time === this.state.mergeShift.end_time || shift.end_time === this.state.mergeShift.start_time) {
        return <div className="control">
          <button className="button is-primary is-responsive" onClick={this.mergeShifts.bind(this, shift)}>Merge!</button>
        </div>
      } else {
        return <div className="control">
          <button className="button is-danger is-responsive" onClick={this.cancelMerge}>Cancel Merge</button>
        </div>
  
      }
    }

    //calllbackend to merge the shifts into one combined shift entry
    mergeShifts = (shift) => {
      const self = this;
      const body = { shiftIdOne: shift.shiftId, shiftIdTwo: this.state.mergeShift.shiftId }
      axios.post("shifts/merge/", body).then((response) => {
        self.props.refreshShifts();
        self.setState({ mergeShift: false })
      }).catch(function (err) {
        console.log(err)
      });
    }
  
    //set state so we can display what shifts can be merged with the selected shift
    setMergeShift = (shift) => {
      this.setState({ mergeShift: shift })
    }

    cancelMerge = () => {
      this.setState({ mergeShift: false })
    }

    mergeBtn = (shift) => {
      if (this.state.mergeShift) {
        return this.mergeOption(shift)
      } else {
        return <div className="control">
          <button className="button is-info is-responsive" onClick={this.setMergeShift.bind(this, shift)} >Merge Shift</button>
        </div>
      }
    }

    reclaimShift = (shift) => {
      axios.get("shifts/reclaim/" + shift._id)
        .then((response) => {
          this.props.refreshShifts();
          this.props.logAction("reclaim shift");
        }).catch((error) => {
          console.log(error);
        });
    }

    mapUpcomingShifts = () => {
        let shifts = this.props.upcomingShifts;
        let dateFormat = "eee dd MMM";
        let timeFormat = "hh:mmaaaa";
        return shifts.map((shift, index) => {
            let tiles = [
                <div className='shift-date'  key="date">
                    <p>{format(shift.start_time * 1000, dateFormat)}</p>
                </div>
            ];
            if (shift.status === 'open') {
                tiles.push(
                    <div key="combined">
                        <article className="message is-warning">
                            <div className="message-body">
                                <strong>Note: You are still responsible for this shift unless someone else claims it, or a manager takes you off the shift.</strong>
                                <p className='upcoming-shift-time'>{format(shift.start_time * 1000, timeFormat)} - {format(shift.end_time * 1000, timeFormat)}</p>
                                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Location:</span> {shift.location}</p>
                                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Group:</span> {shift.group} </p>
                                <button className="button is-info is-responsive" onClick={this.reclaimShift.bind(this, shift)}>Reclaim</button>
                            </div>
                        </article>
                    </div>
                );
            } else {
                tiles.push(
                    <div key="info" style={{ marginBottom: '0px' }}>
                        <article className="message is-dark">
                            <div className="message-body">
                                <p className='upcoming-shift-time'>{format(shift.start_time * 1000, timeFormat)} - {format(shift.end_time * 1000, timeFormat)}</p>
                                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Location:</span> {shift.location}</p>
                                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Group:</span> {shift.group} </p>
                                {/* <div className="upcoming-shift-field field is-grouped"> */}
                                <div className="upcoming-shift-field shift-controls">
                                    <div className="control">
                                        <button className="button is-info is-responsive" onClick={this.offerUpShift.bind(this, shift)}>Offer Up</button>
                                    </div>
                                    <div className="control">
                                        <button className="button is-info is-responsive" onClick={this.props.openModal.bind(this, shift)} >Split Shift</button>
                                    </div>
                                    {this.mergeBtn(shift)}
                                </div>
                            </div>
                        </article>
                    </div>
                );
            }
            return (
                <div key={index} className='shift-block'>
                    {tiles}
                </div>
            );
        });
    }

    drawUpcomingShifts = () => {
        if (this.props.noUpcoming) {
            return (
                <div>
                    <div>
                        <div>
                            <h2 className="section-title">No upcoming shifts</h2>
                        </div>
                    </div>
                </div>
            )
        }
        if ((this.props.upcomingShifts || this.props.additionalShifts) && (this.props.noUpcoming === false)) {
            return (
                <div>
                    <div>
                        <div>
                            <h2 className="section-title">Your upcoming shifts</h2>
                        </div>
                        {this.mapUpcomingShifts()}
                    </div>
                </div>
            );
        }
    }

    render() {
        return <div className='home-section'>
        <div>
          <div className="upcoming-shift">
                    {this.drawUpcomingShifts()}
                </div>
            </div>
        </div>
    }
}