import axios from 'axios';

export const teamsNotify = (shift, group, title) => {
    console.log("Notify!! Dropped Shift: ", shift)
    // console.log("GROUP: ", group)

    axios.post(`hooks/drop_shift/${process.env.REACT_APP_NOTIFICATION_KEY}`, {shift: shift, title: title}).then((response) => {
        console.log("NOTIF RES: ", response);
    }).catch((error) => {
        console.log(error)
    });
}