import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home";
import ManageSchedule from "./manageSchedule/manageScheduleIndex";
import SamlConsume from "./saml/consume";
import ProfileIndex from "./profile/profileIndex";
import AdminIndex from "./admin/adminIndex";
import EmployeeIndex from "./employees/employeeIndex";
import Calendar from "./schedule/calendar";
import Nbar from "./navbar";
import HomeIndex from "./home/homeIndex";
import ScheduleIndex from "./schedule/index";
import HoursReportsIndex from "./hours&reports/hoursReportsIndex";
import PrivateRoute from "./common/privateRoute";
import DigitalSign from "./saml/digitalSign";
import { DigitalSignOH, DigitalSignOHLarge } from "./saml/digitalSignOH";
import GroupIndex from "./manageGroup/groupIndex";
import Banner from "./banner/banner";
import BannerIndex from "./banner/index";
import ShiftrDocument from "./common/shiftrDocument";
import TaskList from "./taskList";

class AppRouter extends Component {

  samlLogout = () => {
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_EXPRESS_HOST}/saml/logout`;
  };

  render() {
    let navbar;
    let showPage = (
      <>
        <Route path="/calendar">
          <div className="container">
            <Calendar />
          </div>
        </Route>
        <Route path="/" exact={true}>
          <Home />
        </Route>
      </>
    );
    if (localStorage.getItem("loggedIn")) {
      navbar = <Nbar logout={this.samlLogout} />;

      if (localStorage.getItem("role") === "guest") {
        showPage = (
          <PrivateRoute path="/">
            <ScheduleIndex />
          </PrivateRoute>
        );
      } else {
        showPage = (
          <>
            <PrivateRoute path="/shiftr-document/:start/:end/:groups">
              <ShiftrDocument />
            </PrivateRoute>

            <PrivateRoute path="/calendar">
              <div className="container">
                <Calendar />
              </div>
            </PrivateRoute>

            <PrivateRoute path="/schedule">
              <ScheduleIndex />
            </PrivateRoute>

            <PrivateRoute path="/banner">
              <BannerIndex />
            </PrivateRoute>

            {localStorage.getItem("role") !== "employee" ?
              <>
                <PrivateRoute path="/manage_schedule">
                  <ManageSchedule />
                </PrivateRoute>

                <PrivateRoute path="/manage_groups">
                  <GroupIndex />
                </PrivateRoute>

                <PrivateRoute path="/employees">
                  <EmployeeIndex />
                </PrivateRoute>

                <PrivateRoute path="/list">
                  <TaskList />
                </PrivateRoute>

                <PrivateRoute path="/shiftr_docs" component={() => {
                  window.location.href = 'https://duke.box.com/s/jpj8e0dtklfbyjyfdqnll5p3p68w7n9k';
                  return null;
                }} />
              </> : <PrivateRoute path="/shiftr_docs" component={() => {
                window.location.href = 'https://duke.box.com/s/1azk7dn8vlcv4szmb5ptrdkizhh6de6r';
                return null;
              }} />}

            <PrivateRoute path="/hours_and_reports">
              <HoursReportsIndex />
            </PrivateRoute>

            <PrivateRoute path="/profile">
              <ProfileIndex />
            </PrivateRoute>

            {localStorage.getItem("role") === "admin" || localStorage.getItem("actAs") === "true" ? (
              <PrivateRoute path="/admin" exact={true}>
                <AdminIndex />
              </PrivateRoute>
            ) : (
              ""
            )}

            <PrivateRoute path="/" exact={true}>
              <HomeIndex />
            </PrivateRoute>
          </>
        );
      }
    }

    return (
      <div>
        <Router>
          {window.location.pathname.includes('iframe') ? <Route path="/iframe">
            <ScheduleIndex />
          </Route> :
            window.location.pathname.includes('bannerIframe') ? <Route path="/bannerIframe">
              <Banner />
            </Route> :
              window.location.pathname.includes('websiteIframe') ? <Route path="/websiteIframe">
                <Calendar colabWebsite="true" />
              </Route> :
                window.location.pathname.includes('digitalSign') ? <Switch>
                  <Route path="/digitalSign/MPS">
                    <DigitalSign location="MPS" />
                  </Route>
                  <Route path="/digitalSign/TEC">
                    <DigitalSign location="TEC" />
                  </Route>
                  <Route path="/digitalSign/Ruby">
                    <DigitalSign location="Ruby" />
                  </Route>
                  <Route path="/digitalSign/Lilly">
                    <DigitalSign location="Lilly" />
                  </Route>
                  <Route path="/digitalSign/OH">
                    <DigitalSignOH />
                  </Route>
                  <Route path="/digitalSign/OHLarge">
                    <DigitalSignOHLarge />
                  </Route>
                </Switch> :
                  <div>
                    {navbar}
                    <div id="global-flash"></div>
                    <Switch>
                      <Route path="/saml/consume">
                        <SamlConsume />
                      </Route>
                      {showPage}
                    </Switch>
                  </div>}
        </Router>
      </div>
    );
  }
}

export default AppRouter;
