import axios from 'axios';
import React, { Component } from 'react';
import replacement from './duke.png';
import HoverInfo from '../common/hoverInfo';
//import imageCompression from 'browser-image-compression';

class ProfileInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      user: {
        netid: '',
        userName: 'Name',
        displayName: 'Name',
        email: 'duke@duke.edu',
        hours: 0,
        id: ''
      },
      image: ''
    }
  }

  showImage = () => {
    axios.get(
      `users/profilePic`
    ).then((response) => {
      if (response.data === '') {
        this.setState({ image: replacement })
      } else {
        // console.log(response.data);
        this.setState({ image: response.config.baseURL + '/' + response.config.url });
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  //get user information
  getUser = () => {
    axios.get("users/getUser").then((response) => {
      console.log(response.data);
      let newUser = {
        netid: response.data.netid,
        displayName: response.data.display_name ? response.data.display_name : response.data.name,
        userName: response.data.name,
        email: response.data.email,
        hours: response.data.preferredHours ? response.data.preferredHours : 1,
        id: response.data._id,
        phone: response.data.phone || '',
        phoneAddress: response.data.phoneAddress
      }
      this.setState({ user: newUser })
      this.showImage();
    }).catch((error) => {
      console.log(error)
    });
  }

  componentDidMount() {
    this.getUser();
  }

  //checks if the input to upload image has changed
  handlePictureChange(e) {
    this.readURI(e);
    if (this.props.onChange !== undefined)
      this.props.onChange(e); // propagate to parent component
  }

  //gets the uploaded file's uri and changes the state profile's uri
  readURI(e) {
    if (e.target.files && e.target.files[0]) {
      this.setState({ file: e.target.files[0] });
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      // console.log(e.target.files[0]);
      // reader.readAsArrayBuffer(e.target.files[0])
      reader.onload = function (ev) {
        // console.log(ev.target.result );
        this.setState({ image: ev.target.result });
      }.bind(this);
    }
  }

  //updates the display name change
  handleChange = (event) => {
    var newUser = this.state.user;
    newUser[event.target.name] = event.target.value;
    this.setState({ user: newUser });
  }

  //update the user profile
  handleSave = () => {
    axios.put(
      "users/updateUser",
      this.state.user
    ).then((response) => {
      this.props.setFlash(false, ['Your profile is saved']);
      this.getUser();
    }).catch((error) => {
      // use error.response.data to get customize error message!
      this.props.setFlash([error.response.data], false);
    });
  }

  //update the user profile
  handleDisable = () => {
    if (window.confirm("Are you sure you want to remove this phone number to disable text reminder?")) {
      axios.put(
        "users/disablePhone",
        {netid: this.state.user.netid }
      ).then((response) => {
        this.getUser();
        this.props.setFlash(false, ['Text reminder is disabled']);
      }).catch((error) => {
        // use error.response.data to get customize error message!
        this.props.setFlash([error.response.data], false);
      });
    }
  }

  //COME BACK AND WORK ON THIS
  handleUpload = () => {
    if (this.state.file) {
      // console.log(this.state.file)
      let formData = new FormData();
      formData.append('profile-file', this.state.file);
      axios.post(
        "users/upload",
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
      ).then((response) => {
        this.getUser();
        this.props.setFlash(false, ['Your picture is saved']);
      }).catch((error) => {
        console.log(error)
      });
    }

  }

  //cancel changes to user profile
  handleCancel = () => {
    this.getUser();
    this.props.setFlash(false, false);
  }

  //creates the profile section including profile picture, user name, display name, netid, email, group and role
  //  action="http://localhost:8080/users/upload" encType="multipart/form-data" method="post"
  // onClick={this.handleUpload}
  createImage = () => {
    return (
      <form>
        <div className="columns">
          <div className="column is-offset-4">
            <div className="field">
              <div className="control">
                <img className="image" src={this.state.image} width="300" height="300" alt="profile pic" />
              </div>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <div className="file">
                  <label className="file-label">
                    <input className='file-input' type="file" name="profile-file" onChange={this.handlePictureChange.bind(this)} />
                    <span className="file-cta">
                      <span className="file-label">Choose</span>
                    </span>
                  </label>
                </div>
              </div>
              <div className="control">
                <input className='button is-link' type='button' value='Upload' onClick={this.handleUpload} />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  createForm = () => {
    return (
      <form>

        <div className="columns">
          <div className="column is-4">
            <div className="field">
              <label className="label">Full Name</label>
              <div className="control">
                <p>{this.state.user.userName}</p>
              </div>
            </div>

            <div className="field">
              <label className="label">Display Name</label>
              <div className="control">
                <input className='input' name='displayName' type="text" value={this.state.user.displayName} onChange={this.handleChange} />
              </div>
            </div>

            <div className="field">
              <label className="label">NetId</label>
              <div className="control">
                <p>{this.state.user.netid}</p>
              </div>
            </div>
          </div>
          <div className="column is-4">

            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <p>{this.state.user.email}</p>
              </div>
            </div>
            <div className="field">
              <label className="label">
                Phone number
              <HoverInfo key="info" message="If you register your phone number, you will receive a text reminder of your shifts: at the beginning of the week, the start of the day and an hour before your shift." />
              </label>
              {this.state.user.phoneAddress? <label className="checkbox">
                <input type="checkbox" onClick={this.handleDisable} />
                Disable
              </label>
              : ''}
              <p>
                {this.state.user.phoneAddress === "invalid" ?
                  <i className='red'>You phone number is invalid, please update!</i> : <i>Enter a valid US phone number to receive text reminders.</i>}</p>
              <div className="control">
                <input className="input" name="phone" type="number" placeholder='0000000000' value={this.state.user.phone || ''} onChange={this.handleChange} />
              </div>
            </div>
          </div>

          <div className="column is-3">
            <div className="field">
              <label className="label">Preferred Hours</label>
              <div className="control">
                <input className="input" name="hours" type="number" min="1" max="40" placeholder="between 1 to 40 hours" value={this.state.user.hours} onChange={this.handleChange} />
              </div>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <button className="button is-link" type="button" onClick={this.handleSave}>Save</button>
              </div>
              <div className="control">
                <button className="button is-light" type="button" onClick={this.handleCancel}>Cancel</button>
              </div>
              <div className="control">
                <button className="button is-light" type="button" onClick={this.handleMessage}>Test Message</button>
              </div>
            </div>
          </div>
        </div>
      </form >
    )
  }

  handleMessage = () => {
    console.log(this.state.user)
    // axios.get("message/test")
    axios.post("users/testMessage", { user: this.state.user })
      .then(() => {
        this.props.setFlash(false, ['Message Sent']);
      }).catch((error) => {
        console.log(error)
        this.props.setFlash([error.response.data], false);
      });
  }

  // do another lookup if the phone address is not found
  // then throw an error
  // Quote: Sandra says: "Julie Williams is going to love this for timecards".
  // and then on employee page, add an icon if they have a phone number
  // handleMessage = () => {
  //   axios.get("users/anniMessage")
  //     .then(() => {
  //       this.props.setFlash(false, ['Message Sent']);
  //     }).catch((error) => {
  //       console.log(error)
  //       this.props.setFlash([error.response.data], false);
  //     });
  // }

  //create a card that contains the profile form
  createCard = () => {
    return (
      <div className="card">
        <div className="card-content">
          {this.createImage()}
          {this.createForm()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <h1 className="title is-4">Profile</h1>
        {this.createCard()}
      </div>
    )
  }
}

export default ProfileInfo;