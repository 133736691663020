import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Flash from '../common/flash';
import ShowAvailability from './showAvailabilities';
import Table from '../common/table';

class EmployeeIndex extends Component {
  constructor(props) {
    super()
    this.state = {
      users: false,
      selectedUser: false,
      adhoc: false,
      tableUsers: false,
      chosenGroup: localStorage.getItem('group').split(",")[0] || false,
      ipGroupDisplay: false,
      columns: [
        { title: "Name", data: 'name' },
        { title: "Role", data: 'role', },
        { title: "Netid", data: 'netid' },
        { title: "Email", data: 'email' },
        { title: "Groups", data: "group" },
        { title: "Pref. Hours", data: 'hours' },
        { title: "Action", data: 'adhoc' },
      ],
      columnDefs:
        [
          {
            targets: [1],
            createdCell: (td, cellData) => {
              ReactDOM.render(
                <div className="select role-select">
                  <select name={cellData.netid} onChange={this.changeHandler}>
                    <option key={cellData.role} value={cellData.role}>{cellData.role}</option>
                    <option value={cellData.role === "employee" ? "supervisor" : "employee"}>{cellData.role === "employee" ? "supervisor" : "employee"}</option>
                  </select>
                </div>, td)
            },
          },
          {
            targets: [6],
            createdCell: (td, cellData) => {
              ReactDOM.render(
                <ShowAvailability updateUsers={this.componentDidMount} setFlash={this.setFlash} user={cellData}
                  adhoc={cellData.adhoc} updateAdHoc={this.updateAdHoc.bind(this)} />
                , td)
            },
          },
        ],
    }
  }

  componentDidMount() {
    this.displayIPs();
    this.getUsersByGroup();
  }

  getUsersByGroup = () => {
    axios.get(`users/employee_list/${this.state.chosenGroup}`).then((response) => {
      // console.log("users are", response.data);
      this.setState({ users: response.data }, () => {
        axios.get("workGroups/findAdHoc").then((response) => {
          this.setState({ adhoc: response.data.adhoc }, this.mapUsers);
        }).catch((error) => {
          console.log(error)
        });
      });
    }).catch((error) => {
      console.log(error)
    });
  }

  getUsers = () => {
    let self = this;
    axios.get("users/employee_list").then((response) => {
      self.setState({ users: response.data });
    }).catch((error) => {
      console.log(error)
    });
  }

  getAdHoc = () => {
    let self = this;
    axios.get("workGroups/findAdHoc").then((response) => {
      self.setState({ adhoc: response.data.adhoc });
    }).catch((error) => {
      console.log(error)
    });
  }

  updateAdHoc = (adhoc) => {
    let self = this;
    axios.post("workGroups/updateAdHoc", adhoc).then((response) => {
      self.getAdHoc();
    }).catch((error) => {
      console.log(error)
    });
  }

  // generic change handler
  changeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (window.confirm('Are you sure you want to change the role?')) {
      this.updateRole(name, value);
    } else {
      // Do nothing!
      console.log('Never mind');
    }
  }

  updateRole = (netid, role) => {
    axios.put("users/updateUserRole", { netid: netid, role: role })
      .then((res) => {
        this.setFlash(false, "User successfully updated!");
      })
      .catch((error) => {
        this.setFlash(error, false);
      });
  }

  mapUsers = () => {
    const users = this.state.users;
    let newUsers = [];
    if (users && users.length > 0) {
      // console.log(users)
      users.forEach((user, index) => {
        user.adhoc = this.state.adhoc;
        newUsers[index] = {
          name: user.display_name || user.name,
          role: user,
          netid: user.netid,
          email: user.email,
          group: Array.isArray(user.group) ? user.group.join(', ') : user.group,
          hours: user.preferredHours,
          adhoc: user
        }
      }
      )
      this.setState({ tableUsers: newUsers });
    }
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  };

  //show error/success message
  flash = () => {
    let ret = [];

    if (this.state.success) {
      ret.push(<Flash key="success" type="success" title="Success" messages={["Your edit has been saved!"]} closeFlash={() => this.setState({ success: false })} />);
    }

    if (this.state.error) {
      let errTitle = "Incomplete Form";
      let errMessage = this.state.error.toString();

      //check for systen error
      if (errMessage.slice(0, 5) === "SYS!:") {
        errTitle = "System Error";
        errMessage = errMessage.slice(5, errMessage.length);
      }

      ret.push(
        <Flash key="error" type="error" title={errTitle} messages={[errMessage]} closeFlash={() => this.setState({ error: false })} />
      );

    }

    return ret;
  };

  //get group data
  getGroupData = (group) => {
    return axios.get("workGroups/find/" + group);
  }

  //update group employee
  updateGroup = () => {
    const self = this;
    let groups = localStorage.getItem('group').split(',').filter(g => g);
    groups.forEach(async (group) => {
      let response = await this.getGroupData(group);
      // console.log("response")
      // console.log(response)
      if (response.status !== 200) {
        console.log("could not find group");
      } else {
        if (response.data && response.data.grouperPath) {
          self.grouperSync(response.data.name, response.data.grouperPath)
        } else {
          console.log("no grouper path");
        }
      }
    });
  }

  //fetch user information from streamer
  //TODO BUG: the page has to be refreshed to see the updated user, unsure as to why :( -Danai
  grouperSync = (groupName, grouperPath) => {
    const self = this;
    axios.get("workGroups/fetchMembers/" + groupName + '/' + grouperPath)
      .then(response => {
        self.getUsers()
        // self.setState({ success: ["Your group has been updated with Grouper."], error: false });
        window.location.reload();
      }).catch((error) => {
        console.log(error)
      });
  }

  loadGroup = async () => {
    let group = this.state.chosenGroup;
    let groupData;
    if (group) {
      let response = await this.getGroupData(group);
      if (response.data) {
        groupData = response.data;
        this.setState({ error: false });
      }
      else {
        let error = ("Cannot load group(s): " + group + ". Group name(s) may have been changed. You need to log out and log back in.");
        console.log("ERROR: ", error);
        this.setState({ error: ("SYS!:" + error), success: false });
      }
    }

    return groupData;
  }

  displayIPs = () => {
    let ret = [];

    this.loadGroup().then((group) => {
      // console.log("group: ", group)
      if (group && group.ipRange) {
        ret.push(<div key={group.name}><p><span style={{ fontWeight: 'bold' }}>Group:</span> {group.name}</p><p><span style={{ fontWeight: 'bold' }}>IP Range:</span> {group.ipFrom} - {group.ipTo}</p>< br /></div>)
      } else if (group && group.ips && group.ips.length > 0) {
        ret.push(<div key={group.name}><p><span style={{ fontWeight: 'bold' }}>Group:</span> {group.name}</p><p><span style={{ fontWeight: 'bold' }}>IP Range:</span> {group.ips.map((ip, index) => <span key={index}>{ip.ipFrom} - {ip.ipTo}</span>)}</p>< br /></div>)
      }

      this.setState({ ipGroupDisplay: ret });

    });
  }

  //get group option from the local storage
  groupOptions = () => {
    var options = []
    let groups = localStorage.getItem('group').split(",");
    groups.forEach((group) =>
      options.push(
        <option key={group} className="capitalize-me" value={group}>{group}</option>
      )
    )
    return options
  }

  // set chosen group
  handleSelectGroup = (event) => {
    let value = event.target.value;
    this.setState({ chosenGroup: value }, () => {
      this.displayIPs();
      this.getUsersByGroup();
    });
  }

  groupSelect = () => {
    return <div className="field">
      <div className="control">
        <label className="label">Group:</label>
        <div className="select">
          <select value={this.state.chosenGroup} name="group" onChange={this.handleSelectGroup}>
            {this.groupOptions()}
          </select>
        </div>
      </div>
    </div>
  }

  render() {
    return (
      <div className="container">
        {this.flash()}
        <h1 className="title my-5">Employees</h1>
        {this.groupSelect()}
        {this.state.ipGroupDisplay}
        <div className="field">
          <div className="control">
            <button className="button" type="button" onClick={this.updateGroup}>Grouper Sync</button>
          </div>
        </div>
        <br></br>
        <Table data={this.state.tableUsers} columns={this.state.columns} columnDefs={this.state.columnDefs} />
      </div>
    )
  }
}

export default EmployeeIndex
