import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faQuestionCircle, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { CreateOrEditTasksTable, ShowTasksTable } from './taskTables';
import Flash from '../common/flash';
import { CompletionTasksTable } from './taskTables';
import format from 'date-fns/format'
import toDate from 'date-fns/toDate'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import subWeeks from 'date-fns/subWeeks'
import startOfWeek from 'date-fns/startOfWeek'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import getDate from 'date-fns/getDate'

export default class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listGroup: false,
            listId: 'create',
            edit: false,
            listName: false,
            newTasks: [],
            editTasks: [],
            currentTasks: [],
            completion: [],
            start_date: startOfDay(subWeeks(startOfWeek(new Date()), 2)),
            end_date: endOfDay(new Date()),
        };
    }

    componentDidMount() {
        let group = localStorage.getItem('group').split(",")[0];
        this.findGroupLists(group);
        this.setState({ listGroup: group })
    }


    findGroupLists = async (value, defaultListId = false) => {
        let response = await axios.get("workGroups/showList/" + value);
        if (response.status !== 200) {
            this.setState({ error: ["Could not find group"] });
        } else {
            let data = response.data;
            let listId = data?.tasklists && data?.tasklists[0] ? data?.tasklists[0].listId : 'create';
            if (defaultListId) {
                listId = defaultListId;
            }
            this.setState({ currentTasks: data?.tasklists || [], listId: listId, listName: data?.tasklists[0].listName });
            if (listId !== "create") {
                this.getListCompletion(listId);
            }
        }
    }

    // generic change handler
    changeHandler = (event, adding = false) => {
        let name = event.target.name;
        let value = event.target.value;
        let id = event.target.id;
        const stateName = adding ? "newTasks" : "editTasks";
        if (name === "listName") {
            this.setState({ [name]: value });
        } else if (name === "listGroup") {
            this.findGroupLists(value);
            this.setState({ [name]: value });
        } else if (name === "task-name") {
            let tasks = this.state[stateName]
            tasks[id].name = value;
            this.setState({ [stateName]: tasks });
        } else if (name === "task-type") {
            let tasks = this.state[stateName]
            tasks[id].type = value;
            this.setState({ [stateName]: tasks });
        } else {
            this.setState({ [name]: value });
            if (name === "listId") {
                if (value !== "create") {
                    this.getListCompletion(value);
                    if (this.state.showCompletionDate) {
                        this.setState({ showCompletionDate: 'display-first', listId: value });
                    } else {
                        this.setState({ showCompletionDate: false, listId: value })
                    }
                    let selectedList = this.state.currentTasks.filter(tasks => tasks.listId === value);
                    this.setState({ listName: selectedList.listName })
                } else {
                    this.removeListCompletion();
                    this.setState({ showCompletionDate: false })
                }
            }
        }
    }

    //get group option from the local storage
    groupOptions = () => {
        var options = []
        let groups = localStorage.getItem('group').split(",");
        groups.forEach((group) =>
            options.push(
                <option key={group} className="capitalize-me" value={group}>{group}</option>
            )
        )
        return options
    }

    groupSelect = () => {
        return <div className="field is-grouped">
            <div className="control">
                <label className="label">Group</label>
                <div className="select">
                    <select value={this.state.listGroup} name="listGroup" onChange={this.changeHandler}>
                        {this.groupOptions()}
                    </select>
                </div>
            </div>
            <div className="control">
                <label className="label">List</label>
                <div className="select">
                    <select value={this.state.listId} name="listId" onChange={this.changeHandler}>
                        <option key="create-list" className="capitalize-me" value='create'>-- Create List --</option>
                        {this.state.currentTasks.map((list, i) => <option key={`List-${i}`} className="capitalize-me" value={list.listId}>{list.listName || `List ${i + 1}`}</option>)}
                    </select>
                </div>
            </div>
            {this.state.showCompletionDate ?
                <div className="control">
                    <label className="label">Back to List Editing</label>
                    <button className="button" type="button" onClick={() => this.setState({ showCompletionDate: false })}>Go</button>
                </div> : ''}
        </div>
    }
    addNewTask = (adding) => {
        const stateName = adding ? "newTasks" : "editTasks";
        this.setState(prevState => ({ [stateName]: prevState[stateName].concat({ name: "", type: "checkbox" }) }))
    }

    //delete the last location input field
    removeTask = (index, adding) => {
        const stateName = adding ? "newTasks" : "editTasks";
        this.setState((prevState) => {
            const updatedTasks = [...prevState[stateName]]; // Create a shallow copy
            updatedTasks.splice(index, 1); // Remove the item at the specified index
            return { [stateName]: updatedTasks }; // Update the state with the new array
        });
    }

    deleteList = async (listId) => {
        if (window.confirm("Are you sure you want to delete this list?")) {
            let response = await axios.put(`workGroups/deleteList/${this.state.listGroup}/${listId}`);
            if (response.status !== 200) {
                this.setState({ error: ["Could not delete list"] });
            } else {
                this.findGroupLists(this.state.listGroup);
                this.setState({ edit: false, newTasks: [], completion: [], success: ["Deleted List"] })
            }
        }
    }

    setList = async (adding) => {
        let response;
        if (!adding) {
            response = await axios.put(`workGroups/editList/${this.state.listGroup}/${this.state.edit}`, { tasks: this.state.editTasks, listName: this.state.listName });
        } else {
            let body = {
                tasks: this.state.newTasks,
                display: true,
                listName: this.state.listName,
                dailyTask: { time: "07:00" }
            }
            response = await axios.put(`workGroups/createList/${this.state.listGroup}`, body);
        }
        if (response.status !== 200) {
            this.setState({ error: ["Could not add or edit new list"] });
        } else {
            if (response.data.listId) {
                this.findGroupLists(this.state.listGroup, response.data.listId);
            } else {
                this.findGroupLists(this.state.listGroup, this.state.edit);
            }
            this.resetList(adding);
        }
    }

    pushList = async (listId) => {
        let response = await axios.get(`tasklists/push_list/${this.state.listGroup}/${listId}`);
        if (response.status !== 200) {
            this.setState({ error: ["Could not push list"] });
        } else {
            this.findGroupLists(this.state.listGroup, listId);
            this.setState({ edit: false, newTasks: [], success: ["Pushed List to Employees"] })
        }
    }

    toggleList = async (listId, display) => {
        let response = await axios.put(`workGroups/toggleList/${this.state.listGroup}/${listId}/${display}`);
        if (response.status !== 200) {
            this.setState({ error: ["Could not toggle list"] });
        } else {
            this.findGroupLists(this.state.listGroup, listId);
            this.setState({ edit: false, newTasks: [], success: [display ? 'List Has Been Unpublished' : 'List Has Been Published'] });
        }
    }

    resetList = (adding) => {
        if (adding) {
            this.setState({ newTasks: [], listName: false });
        } else {
            this.setState({ edit: false, editList: [] });
        }
    }

    addList = () => {
        return <div>
            <h2 className="title create-open-shifts-title">Add New List</h2>
            <div className="field">
                <label className="label">List Name</label>
                <div className="control">
                    <input className="input" name="listName" type="text" placeholder="Enter a list name..." onChange={this.changeHandler} />
                </div>
            </div>
            {this.state.newTasks.length === 0 ? "" :
                <CreateOrEditTasksTable adding={true} newTasks={this.state.newTasks} changeHandler={(e) => this.changeHandler(e, true)} removeTask={this.removeTask} />
            }
            <label className="label">Add Task:
                <FontAwesomeIcon className="clickable" icon={faPlusCircle} onClick={() => this.addNewTask(true)} />
            </label>
            <div className="control">
                <button className="button is-info is-light mr-4" type="button" onClick={() => this.setList(true)}>Set List</button>
                <button className="button is-info is-light" type="button" onClick={() => this.resetList(true)}>Nevermind</button>
            </div>
        </div>
    }

    editList = () => {
        if (this.state.listGroup) {
            return <>
                <h2 className="title is-4">Edit list</h2>
                <div className="field">
                    <label className="label">List Name</label>
                    <div className="control">
                        <input className="input" name="listName" type="text" defaultValue={this.state.listName} placeholder="Enter a list name..." onChange={this.changeHandler} />
                    </div>
                </div>
                {this.state.editTasks.length === 0 ? "" :
                    <CreateOrEditTasksTable adding={false} newTasks={this.state.editTasks} changeHandler={(e) => this.changeHandler(e, false)} removeTask={this.removeTask} />
                }
                <label className="label">Add Task:
                    <FontAwesomeIcon className="clickable" icon={faPlusCircle} onClick={() => this.addNewTask(false)} />
                </label>
                {this.state.editTasks.length === 0 ? "" :
                    <div className="control">
                        <button className="button is-info is-light mr-4" type="button" onClick={() => this.setList(false)}>Set List</button>
                        <button className="button is-info is-light" type="button" onClick={() => this.resetList(false)}>Nevermind</button>
                    </div>
                }
            </>
        }
    }

    showCurrentLists = () => {
        let listId = this.state.listId;
        if (listId !== 'create') {
            let findList = this.state.currentTasks.filter(list => list.listId === listId);
            let index = this.state.currentTasks.indexOf(findList[0]);
            let list = this.state.currentTasks[index];
            if (list) {
                return <div key={`show-list-${index + 1}`}>
                    {this.state.edit === list.listId ? this.editList() : <>
                        <div className="field is-grouped">
                            <div className="label">{list.listName || `List ${index + 1}`}</div>
                            <FontAwesomeIcon className="clickable" icon={faEdit} onClick={() => this.setState({ editTasks: JSON.parse(JSON.stringify(list?.tasks)), edit: list.listId, listName: list.listName })} />
                            <FontAwesomeIcon className="clickable" icon={faTrash} style={{ color: '#C70039' }} onClick={() => this.deleteList(list?.listId)} />
                        </div>
                        <ShowTasksTable list={list} />
                    </>}
                    <br />
                    {this.state.edit === list.listId ? '' : <div className="field is-grouped">
                        <div className='tooltipped-button'>
                            <button className="button is-info is-light" type="button" onClick={() => this.toggleList(list?.listId, list?.display)}>{list?.display ? "Unpublish" : "Publish"} List</button>
                            <span className="tooltiptext">{list?.display ? "Remove list from the schedule" : "Put this list on the schedule"}</span>
                        </div>
                        {localStorage.getItem('role') === 'admin' ?
                            <div className='tooltipped-button'>
                                <button className="button is-info is-light" type="button" onClick={() => this.pushList(list?.listId)}>Manual Push</button>
                                <span className="tooltiptext">Why are my employees not seeing the list today? Try sending it manually</span>
                            </div> : ''}
                    </div>}
                </div>
            }
        }
    }

    closeLogs = () => {
        this.setState({ logs: false })
    }

    showLogs = (logs) => {
        this.setState({ logs: logs })
    }

    showCompletionByDate = () => {
        return <>
            {this.state.completion.map((list, i) => {
                if (list.createdAt === this.state.showCompletionDate || (this.state.showCompletionDate === "display-first" && i === 0)) {
                    return <div key={`${list.listId}-completion-${i}`} className="mb-6">
                        <p className="title is-4">{list.listName || 'List'} Completed On {new Date(list.createdAt).toLocaleDateString()}</p>
                        <CompletionTasksTable list={list} logs={this.state.logs} closeLogs={this.closeLogs} showLogs={this.showLogs} />
                    </div>
                }
            })
            }
        </>
    }

    showListCompletion = () => {
        return this.state.completion.map((list, i) =>
            <li key={list.createdAt} className="subtitle is-5 my-4 pointer" onClick={() => this.setState({ showCompletionDate: list.createdAt })}>
                {new Date(list.createdAt).toLocaleDateString()}
            </li>
        )
    }

    removeListCompletion = () => {
        this.setState({ completion: [] })
    }

    getListCompletion = async (listId) => {
        let start = new Date(this.state.start_date);
        let base_date = this.state.end_date;
        let end = new Date(getYear(base_date), getMonth(base_date), getDate(base_date), 23, 59, 59);
        let response = await axios.get(`tasklists/findList/${this.state.listGroup}/${listId}/${start}/${end}`);
        if (response.status !== 200) {
            this.setState({ error: ["Could not get list completion"] });
        } else {
            // console.log("response.data", response.data)
            this.setState({ completion: response.data })
        }
    }

    closeFlash = () => {
        this.setState({ error: false, success: false })
    }

    //show error message
    flash = () => {
        if (this.state.error) {
            return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
        }
        if (this.state.success) {
            return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
        }
    }

    changeDateHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var date_parts = value.split("-")
        var date = toDate(new Date(date_parts[0], date_parts[1] - 1, date_parts[2], 0, 0, 0))

        this.setState({
            [name]: date
        }, () => this.getListCompletion(this.state.listId));
    }

    dateRange = () => {
        return <div className="field is-grouped">
            <div className="control">
                <label className="label">Start Date</label>
                <input
                    className="input"
                    type="date"
                    value={format(this.state.start_date, "yyyy-MM-dd")}
                    name="start_date"
                    onChange={this.changeDateHandler}
                />
            </div>
            <div className="control">
                <label className="label">End Date</label>
                <input
                    className="input"
                    type="date"
                    value={format(this.state.end_date, "yyyy-MM-dd")}
                    name="end_date"
                    onChange={this.changeDateHandler}
                />
            </div>
        </div>
    }

    listCompletionCard = () => {
        const listCompletionView =
            <div className="card">
                <div className="card-content">
                    <h2 className="title is-4">Completed by Date</h2>
                    {this.dateRange()}
                    {this.showListCompletion()}
                </div>
            </div>
        const listEditView =
            <div className="card">
                <div className="card-content">
                    {this.state.showCompletionDate ?
                        this.showCompletionByDate() :
                        this.showCurrentLists()}
                </div>
            </div>
        const addListView = <div className="card">
            <div className="card-content">
                {this.addList()}
            </div>
        </div>
        return this.state.listId !== "create" ?
            (<div className="columns">
                <div className="column is-3">
                    {listCompletionView}
                </div>
                <div className="column">
                    {listEditView}
                </div>
            </div>) :
            addListView
    }

    render() {
        return <div className="container">
            <h1 className="title">Group List</h1>
            <p className="mb-4">Set up <strong>Group Level</strong> checklists for the
                student employees to check off everyday. The list is reset every day at 7am.
                See Completed by Date Panel to view group completion progress.
            </p>
            {this.groupSelect()}
            {this.flash()}
            {this.listCompletionCard()}
        </div >
    }
}