import React, { Component } from "react";
const $ = require("jquery");
$.DataTable = require("datatables.net");

class DataTableComp extends Component {

    componentDidMount() {
        this.$el = $(this.el);
        this.$el.DataTable({
            data: this.props.data,
            columns: this.props.columns,
            columnDefs: this.props.columnDefs,
            scrollX: true
        });
    }

    componentDidUpdate(prevProps) {
        let changedProps = prevProps.data !== this.props.data
        if (changedProps) {
            this.$el.DataTable().destroy();
            this.$el = $(this.el);
            this.$el.DataTable({
                data: this.props.data,
                columns: this.props.columns,
                columnDefs: this.props.columnDefs,
                scrollX: true
            });
        }
    }

    render() {
        return (
            <div>
                <p className="help-message">Table may need horizontal scrolling to show all information.</p>
                <table className="table is-striped"
                    width='100%'
                    id="dataTable"
                    ref={(el) => (this.el = el)}
                />
            </div>
        );
    }
}
export default DataTableComp;