import React, { Component } from 'react';
import Flash from '../common/flash';
import format from "date-fns/format";

class ShiftsModal extends Component {
    constructor(props) {
        super();
        this.state = { employees: null, openShifts: false, oneWeekOnly: null, error: null };
    }

    componentDidMount() {
        let employees = Array.from({ length: this.props.users.length }).map(x => false);
        this.setState({ employees });
    }

    handleEmployeesChange = (event, duid) => {
        let index = this.props.users.findIndex(element => {
            return element.duid === duid;
        });
        let employees = this.state.employees;
        employees[index] = !employees[index];
        this.setState({ employees: employees });
    }

    handleOpenShiftsChange = () => {
        this.setState({ openShifts: !this.state.openShifts });
    }

    handleRadioChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    checkAll = () => {
        this.setState({ openShifts: true });
        this.setState({ employees: Array.from({ length: this.props.users.length }).map(x => true) });
    }

    uncheckAll = () => {
        this.setState({ openShifts: false });
        this.setState({ employees: Array.from({ length: this.props.users.length }).map(x => false) });
    }

    employeesCheckboxes = () => {
        if (this.state.employees) {
            let checkboxes = [];
            let employees = this.state.employees;
            this.props.users.forEach((user, index) => {
                checkboxes.push(
                    <div key={user.display_name}>
                        <label className="checkbox">
                            <input
                                name={user.display_name}
                                type="checkbox"
                                checked={employees[index]}
                                onChange={(event) => this.handleEmployeesChange(event, user.duid)}
                            />
                            &nbsp;
                            {user.display_name}
                        </label>
                    </div>)
            });
            return <>{checkboxes}</>
        }
    }

    updateShifts = (event) => {
        event.preventDefault();
        let error = [];
        const checkedUsers = this.props.users.filter((element, index) => this.state.employees[index]).map(x => x.netid);
        if (checkedUsers.length === 0 && !this.state.openShifts) {
            error.push("Please select employee(s).");
        }
        if (!this.state.oneWeekOnly) {
            error.push("Please select time period.");
        }
        if (error.length > 0) {
            this.setState({ error });
        }
        else {
            this.props.updateShifts(checkedUsers, this.state.openShifts, this.state.oneWeekOnly);
            this.props.closeModal();
        }
    }

    flash = () => {
        if (this.state.error) {
            return <div className="mb-2">
                <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
            </div>
        }
    }

    closeFlash = () => {
        this.setState({ error: null })
    }

    render() {
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{this.props.type === "delete"? "Delete": this.props.type==="publish"? "Publish":"Unpublish"} Shifts</p>
                        <button onClick={this.props.closeModal} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        {this.flash()}
                        <form>
                            <label className="label mb-1">{this.props.type === "delete"? "Delete": this.props.type==="publish"? "Publish":"Unpublish"} shifts belonging to:</label>
                            <div className="tile">
                                <p className="is-clickable" onClick={this.checkAll}>All</p>
                                &nbsp;|&nbsp;
                                <p className="is-clickable" onClick={this.uncheckAll}>None</p>
                            </div>

                            <div key="openShifts">
                                <label className="checkbox">
                                    <input
                                        name="openShifts"
                                        type="checkbox"
                                        checked={this.state.openShifts}
                                        onChange={this.handleOpenShiftsChange}
                                    />
                                    &nbsp;Unassigned
                                </label>
                            </div>

                            <div className='grid-container'>
                                {this.employeesCheckboxes()}
                            </div>

                            <label className="label mb-1 mt-2">Range from:</label>
                            <div className="control">
                                <label className="radio">
                                    <input type="radio" name="oneWeekOnly" value="false" onChange={this.handleRadioChange} />
                                    &nbsp;Full Schedule
                                </label>
                                <label className="radio">
                                    <input type="radio" name="oneWeekOnly" value="true" onChange={this.handleRadioChange} />
                                    &nbsp;Current Week
                                    ({format(this.props.startWeek, "eee M/d")} - {format(this.props.endWeek, "eee M/d")})
                                </label>
                            </div>
                            <br />
                            <button
                                className="button is-danger"
                                type="button"
                                onClick={this.updateShifts}
                            >
                                Apply
                            </button>
                        </form>
                    </section>
                </div>
            </div>
        )
    }
}

export default ShiftsModal
